import React from 'react';
import Helmet from "react-helmet";
import styled from 'styled-components';
import Layout from 'components/Layout';
import SEO from '../components/seo';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const NotFoundDiv = styled.div`
	padding:30px;
`;
const Breadcrumbs = styled.p`
    
    padding: 25px 0px 25px 50px;
	width:100%
`;

const NotFoundPage = () => {
	const intl = useIntl()
	return(
	  <Layout>
	  	<Helmet>
		    
      		<link rel="alternate" href={intl.formatMessage({ id: "404-link" })} hreflang={intl.formatMessage({ id: "language" })} />
      		<link rel="alternate" href={intl.formatMessage({ id: "404-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
      		<link rel="alternate" href={intl.formatMessage({ id: "404-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
      		<link rel="alternate" href={intl.formatMessage({ id: "404-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
	  	</Helmet>
		  <Breadcrumbs><Link to="/">Home</Link> / 404 </Breadcrumbs>
	    <SEO title="404: Not found" />
	    <NotFoundDiv>
	    	<h1>NOT FOUND</h1>
	    	<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	  	</NotFoundDiv>
	  </Layout>
	);
};

export default NotFoundPage;
